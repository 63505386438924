import * as data from './urls.json';

const jsonData = JSON.stringify(data);
const finalData = JSON.parse(jsonData);

const randomUrl = finalData.urls[Math.floor(Math.random() * finalData.urls.length)];

function redirect(url) {
  window.location.replace(url);
}

redirect(randomUrl.url);
